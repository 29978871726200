import { Fragment, useEffect, useContext } from 'react'
import Head from 'next/head'

// components
import Text from '../components/Text/Text'
import Table from '../components/Table/Table'
import ImageBlock from '../components/ImageBlock/ImageBlock'
import VideoBlock from '../components/VideoBlock/VideoBlock'
import Quote from '../components/Quote/Quote'
import Conversation from '../components/Conversation/Conversation'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Gallery from '../components/Gallery/Gallery'
import Seo from '../components/Seo/Seo'

// 404 page
import Custom404 from './404'
import CardList from '../components/CardList/CardList'
import LinkCard from '../components/LinkCard/LinkCard'
import { VideoModalContext } from '../context/VideoModalContext'
import ModalVideo from 'react-modal-video'

const Generic = (props) => {
  const { page } = props

  // For generic pages we need to check if page is set. If not we return the 404 error page.
  if(!props.page) {
    return <Custom404 />
  }

  const videoModalContext = useContext(VideoModalContext)

  // parse metadata fields
  const metadata = {
    ...JSON.parse(props.page.seo.metaTitleContainer).title,
    ...JSON.parse(props.page.seo.metaTagContainer)
  }

  useEffect(() => {
    document.querySelector('body').classList.add('is-generic')
    return () => {
      document.querySelector('body').classList.remove('is-generic')
    }
  }, [])

  return (
    <Fragment>
      <Seo {...metadata}/>

      <Header {...page.header[0]} alignment={page.header[0].imageAlignment} />

      {props.page.components.map((component, index) => {
        switch (component.type) {
          case 'text':
            return (
              <Text key={`${component.type}-${index}`} {...component} />
            )
          case 'gallery':
            return (
              <Gallery key={`${component.type}-${index}`} {...component} />
            )
          case 'table':
            return (
              <Table {...component} key={`${component.type}-${index}`}/>
            )
          case 'image':
            return (
              <ImageBlock {...component} key={`${component.type}-${component.id}`}/>
            )
          case 'video':
            return (
              <VideoBlock key={`${component.type}-${component.id}`} {...component} />
            )
          case 'quote':
            return (
              <Quote key={`${component.type}-${component.id}`} {...component} />
            )
          case 'conversation' :
            return (
              <Conversation key={`${component.type}-${component.id}`} {...component} />
            )
          case 'referral':
            return (
              <CardList layout={`stacked`} key={`CardList-${component.id}`}>
                <LinkCard {...component} key={`LinkCard-${component.id}-${index}`}/>
              </CardList>
            )
        }
      })}

      <ModalVideo channel={videoModalContext.videoChannel} isOpen={videoModalContext.videoOpen} videoId={videoModalContext.videoId} onClose={() => videoModalContext.closeVideoModal() } />

      <Footer/>

    </Fragment>
  )
}

export async function getServerSideProps (context) {
  const query = require('../queries/gqlGeneric.graphql')

  // api endpoint
  let apiUrl = process.env.API_URL

  const queryData = {
    variables: {
      slug: context.params.generic,
      uri: `${context.params.generic}`
    },
    query
  }

  const opts = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(queryData)
  }

  // update api url to enable live and share preview
  if (context.query) {
    if (context.query.token && !!(context.query['x-craft-live-preview'] || context.query['x-craft-preview'])) {
      apiUrl = `${process.env.API_URL}?token=${context.query['token']}`
    }
  }

  const res = await fetch(apiUrl, opts)
  const json = await res.json()

  return {
    props: {
      page: (json.data && json.data.entry) ? json.data.entry : false
    }
  }
}

export default Generic
