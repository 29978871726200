/**
 * Header
 *
 * To be used has a generic Header for pages.
 */

import { Fragment } from 'react'
import { useRouter } from 'next/router'

import ImageBlock from '../ImageBlock/ImageBlock'
import Svg from '../Svg/Svg'

const Header = (props) => {

  const { title, description, style, image, alignment, caption, back, published } = props

  // Add modifier class to component
  // @todo add different layout for news detail page
  // @todo add back button for news detail page
  // @todo add date for news detail page
  const modifier = style ? ` Header--${style}` : ``

  const router = useRouter()

  const descriptionText = () => {
    return { __html: description }
  }

  return (
    <Fragment>
      <div className={`Header${modifier}`}>
        <div className={`Header-inner`}>
          {back &&
          <div className={`Header-back`} onClick={() => router.back()}><Svg id={`icon-chevron-right`} modifier={`Header-svgBack`}/><span
            className={`Header-backLabel`}>Back</span></div>
          }
          <h1 className={`Header-title`}>{title}</h1>
          {description &&
          <div className={`Header-content`}>
            <div className={`Header-description`} dangerouslySetInnerHTML={descriptionText()}/>
          </div>
          }
          { published &&
          <div className={`Header-published`}>
            {published}
          </div>
          }
        </div>
      </div>
      {image &&
      <ImageBlock alignment={alignment ? alignment : `right`} image={image} caption={caption}/>
      }
    </Fragment>
  )
}

export default Header
