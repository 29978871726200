const Svg = (props) => {
  const { modifier, id } = props

  // do not render the SVG without an id
  if (!id) return ``

  return (
    <svg className={`Svg ${modifier}`}>
      <use xlinkHref={`#${id}`}/>
    </svg>
  )
}

export default Svg
